//
// Custom alerts
//

.alert-dark {
  .alert-variant(rgba(0,0,0,.9); rgba(0,0,0,.8); #fff);

  .close {
    text-shadow: 0 1px 0 #000;
  }
  .close:hover, .close:focus {
    color: #fff;
  }
}

.alert-full {
  border-radius: 0;
}
.growlhide{
	display:none;
}
.searchresults{
	display:none;
	right: inherit !important;
	top: inherit !important;
	width: 300px !important;
	height: 500px; overflow: auto;
}
