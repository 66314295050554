.container-fill-height {
  display: table;
  width: 100%;
  height: 75vh;

  .container-content-bottom,
  .container-content-middle {
    display: table-cell;
    vertical-align: middle;
  }

  .container-content-bottom {
    vertical-align: bottom;
  }
}

.container-fluid-spacious {
  @media (min-width: @screen-sm-min) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
