.bulletplus,.bulletplusc,.clickable,.vidclicker,.likeit,.editphoto,.pinit{
	cursor:pointer;
}
.clickable:hover{
	color: @link-hover-color;
    text-decoration: none;
}
.postoption{
	font-size: 12px;
	position: absolute;
	left: 5px;
	top: 15px;
	display:none;
}
.postoption ul{
	list-style-type: none;
	padding: 5px;
}
.loginform{
	color:#fff;
	font-size: 11px;
}
.btn-logtwitt{
	top: 11px;
	left: 25px;
}
.btn-logtwitt button{
	font-size: 12px;
}
.forgot{
	position: absolute;
	right: 25px;
	top: 57px;
	color: #fff;
	font-size: 12px;
}
.forgot2{
	
	color: #fff;
	float:right;
}
.home_filters {
	padding: 15px 0 10px 0;
	left: 0;
	-webkit-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
	text-align: center;
	margin-top: 5px;
	margin-bottom:40px;
}
ul.navigation_categories {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;
}
ul.navigation_categories li {
    display: inline-block;
    padding: 0 5px;
    line-height: 32px;
    position: relative;
}
ul.navigation_categories li.navigation_dropdown > a {
    background: url('/img/arrow_down.png') center right no-repeat;
}
ul.navigation_categories li > a {
    display: inline-block;
    line-height: 32px;
    padding: 0 10px;
    font-weight: normal;
}
.optionspn{
	margin-top:-39px;
	margin-bottom:15px;
	width:75%;
	-webkit-box-shadow: inset 0 0px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
	text-align: center;
	margin-left: 14%;
	padding: 10px;
	display:none;
	background-color: @body-bg;
	position:absolute;
	z-index: 2;

}
.inline{
	display:inline;
	padding-right: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.validbuttons{
	float: right;
}
.cust2{
	top: 5px;
}
.videoiframe{
	padding:20px;
}

.numlikes{
	float:right;
	padding: 1px 5px;
}
.liked{
	color:@link-color;
	
}
.likeit{

	&:hover,
	  &:focus {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	  }

	  &:focus {
		.tab-focus();
	  }
}
#append{
margin: auto;
width: 50%;
border: 0px;
padding: 10px;
}
#append img{
margin: auto;

display: block;
}
.js-conversation,.myboardnotif{
	display:none;
}
.formcomment{
	padding-top: 10px;
	padding-bottom: 10px;
}
.notif{
	background-color: #fa3e3e;
	border-radius: 3px;
	color: #fff;
	padding: 2px 4px;
	font-size: 11px;
	top: -10px;
	position: relative;
	right: 11px;
}
.editphoto{
	padding-top: 80px;
	position: relative;
	float: left;
}
.photoplus{
	left: 0px;
	position: absolute;
	text-align: center;
	top: 50px;
	width: 100%;
	display:none;
}
.pinit{
	color: @link-color;
}
.pined{
	color:#f00;
}
.playlogo{
	position: absolute;
	left: 50%;
	top: 35%;
	font-size: 75px;
	color: #fff;
	background-color: #000;
	opacity: 0.3;
	border-radius: 24px;

	height: 105px;
	width: 105px;
}
.playlogo span{
	padding-left: 17px;
}
.vidclicker:hover .playlogo {
    opacity: 1;
    color: @link-hover-color;
    background-color:@body-bg;
}
.profile-img-big {
    
    border: 3px solid #fff;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.okvalid{
	color: #4eb76e;
	display:none;
	font-size: 200px;
	margin-top: -320px;
}
.oknonvalid{
	color: #f00;
	display:none;
	font-size: 200px;
	margin-top: -320px;
}
.validgenre, .validgenre:active, .validgenre:focus {
	outline: none;
}
.showtrans{
	float:right;
}
.transmodel{
	display:none;
}
.pnclass{
	margin-top: 10px;
	padding-top: 10px;
	margin-bottom: 10px;
	border-top-width: 1px;
	border-top-color: @link-color;
	border-top-style: ridge;
}



@media (min-width: @screen-sm-min){
	.showtrans{
		margin-top:-35px;
	}
	.pnclass{
		border-top-width: 0px;
	}
	
}
